import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoutes";
import Home from "../components/Home/Home";
import Movies from "../components/Movies/Movies";
import CategoryList from "../components/CategoryList/CategoryList";
import PartnerShows from "../components/PartnerShows/PartnerShows";
import RecentlyAdded from "../components/RecentlyAdded/RecentlyAdded";
import SignIn from "../components/SignIn/SignIn";
import Register from "../components/Register/Register";
import Series from "../components/Series/Series";
import AboutUs from "../components/FooterComponents/AboutUs/AboutUs";
import Search from "../components/Search/Search";
import Header from "../components/Basic/Header";
import Footer from "../components/Basic/Footer";
import SubscriptionList from "../components/SubscriptionList/SubscriptionList";
import Landing from "../components/Landing/Landing";
import Success from "../components/Payment/Succes";
import Error from "../components/Payment/Error";
import Payment from "../components/Payment/Payment";
import VideoPlayer from "../components/Video/VideoPlayer";
import TermsOfUse from "../components/FooterComponents/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "../components/FooterComponents/PrivacyPolicy/PrivacyPolicy";
import PressRelease from "../components/FooterComponents/PressRelease/PressRelease";
import AdvertiseWithUs from "../components/FooterComponents/AdvertiseWithUs/AdvertiseWithUs";
import Contact from "../components/FooterComponents/Contact/Contact";
import ContactSupport from "../components/FooterComponents/ContactSupport/ContactSupport";
import SupportedDevices from "../components/FooterComponents/SupportedDevices/SupportedDevices";
import PartnerList from "../components/PartnerList/PartnerList";
import WebTermsOfUse from "../components/FooterComponents/WebTermsOfUse/WebTermsOfUse";
import WebPrivacyPolicy from "../components/FooterComponents/WebPrivacyPolicy/WebPrivacyPolicy";
import CookiePolicy from "../components/FooterComponents/CookiePolicy/CookiePolicy";
import Activate from "../components/Activate/Activate";
import LiveContainer from "../components/Home/LiveContainer";
const routes = () => (
  <Router>
    <Header />
    <div className="closeMenuWrapper">
      <Switch>
        <PublicRoute path="/" exact component={Landing} />
        <PublicRoute path="/home" exact component={Home} />
        <PublicRoute path="/home/movies" exact component={Movies} />
        <PublicRoute path="/home/categorylist" exact component={CategoryList} />
        <PublicRoute
          path="/home/recentlyadded"
          exact
          component={RecentlyAdded}
        />
        <Route path="/signin" exact component={SignIn} />
        <Route path="/register" exact component={Register} />
        <PublicRoute path="/home/series" exact component={Series} />
        <PublicRoute path="/aboutus" exact component={AboutUs} />
        <PublicRoute path="/search" exact component={Search} />
        <PublicRoute path="/subscription" exact component={SubscriptionList} />
        <PublicRoute path="/success" exact component={Success} />
        <PublicRoute path="/error" exact component={Error} />
        <PublicRoute path="/payment" exact component={Payment} />
        <PublicRoute path="/videoplayer" exact component={VideoPlayer} />
        <PublicRoute path="/termsofuse" exact component={TermsOfUse} />
        <PublicRoute path="/policydarkmode" exact component={PrivacyPolicy} />
        <PublicRoute path="/pressrelease" exact component={PressRelease} />
        <PublicRoute
          path="/advertisewithus"
          exact
          component={AdvertiseWithUs}
        />
        <PublicRoute path="/contactus" exact component={Contact} />
        <PublicRoute path="/contactsupport" exact component={ContactSupport} />
        <PublicRoute path="/supportdevice" exact component={SupportedDevices} />
        <PublicRoute path="/partnerList" exact component={PartnerList} />
        <PublicRoute
          path="/termsandconditions"
          exact
          component={WebTermsOfUse}
        />
        <PublicRoute path="/privacypolicy" exact component={WebPrivacyPolicy} />
        <PublicRoute path="/home/live" exact component={LiveContainer} />
        <PrivateRoute path="/tv" exact component={Activate} />

      </Switch>
    </div>
    <Footer />
  </Router>
);
export default routes;
