import React from "react";
import { Route, Redirect,useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {service} from '../network/service';

const PrivateRoute = ({ component: Component, ...rest }) => {
    let location = useLocation();
  const isLogin = useSelector((state) => state.login);
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  let userId = service.getCookie("userId");
  localStorage.setItem("location", "/tv");
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn === "true" && userId ? (
          <Component {...props} />
        ) : location.pathname === "/tv" ? (
          <Redirect
            to={{ pathname: "/register", state: { from: props.location } }}
          />
        ) : (
          <Redirect
            to={{ pathname: "/signin", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
